<script setup>
import {navigateTo} from "nuxt/app";

definePageMeta({
  layout: 'classroom',
});

navigateTo('/login');
</script>

<template>

</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>
