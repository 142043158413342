import {defineNuxtPlugin, useCookie, useFetch, useRequestHeaders, useRuntimeConfig} from "nuxt/app";

export default defineNuxtPlugin(() => {
    return {
        provide: {

            get: async (url, method = "GET", data = null) => {

                const config = useRuntimeConfig()

                let opts = {

                    method: method,
                    baseURL: config.public.baseUrl,
                    headers: {
                        'X-XSRF-TOKEN-PORTAL': useCookie('XSRF-TOKEN-PORTAL').value,
                    },
                    credentials: 'include',
                };

                if(data){
                    opts.body = JSON.stringify(data);
                }

                return useFetch(() => url, opts);
            },

        }
    }
})
