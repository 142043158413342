import { default as _91id_93iSazJfvhUFMeta } from "/vercel/path0/pages/classes/[id].vue?macro=true";
import { default as completedeTS4ST2nFOMeta } from "/vercel/path0/pages/classes/completed.vue?macro=true";
import { default as createYNeHYeATl7Meta } from "/vercel/path0/pages/classes/create.vue?macro=true";
import { default as index0GHCrg5TyqMeta } from "/vercel/path0/pages/classes/index.vue?macro=true";
import { default as _91id_93y4mDZ8ZBInMeta } from "/vercel/path0/pages/classroom/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93945zNMKXhzMeta } from "/vercel/path0/pages/lesson-report/[id].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91id_93doBmNU7TLGMeta } from "/vercel/path0/pages/notes/[id].vue?macro=true";
import { default as _91token_93xuG8tmUSKdMeta } from "/vercel/path0/pages/recording/[token].vue?macro=true";
import { default as StudentSessionRowEnS3FqZ6uQMeta } from "/vercel/path0/pages/student/components/StudentSessionRow.vue?macro=true";
import { default as completed04Cu4gw5jIMeta } from "/vercel/path0/pages/student/lessons/completed.vue?macro=true";
import { default as indexyQLQbJARQqMeta } from "/vercel/path0/pages/student/lessons/index.vue?macro=true";
import { default as _91id_93BlRZk9wzsdMeta } from "/vercel/path0/pages/students/[id].vue?macro=true";
import { default as createH3MwhtYC9UMeta } from "/vercel/path0/pages/students/create.vue?macro=true";
import { default as indexgabsFWvsCkMeta } from "/vercel/path0/pages/students/index.vue?macro=true";
export default [
  {
    name: _91id_93iSazJfvhUFMeta?.name ?? "classes-id",
    path: _91id_93iSazJfvhUFMeta?.path ?? "/classes/:id",
    children: [],
    meta: _91id_93iSazJfvhUFMeta,
    alias: _91id_93iSazJfvhUFMeta?.alias || [],
    redirect: _91id_93iSazJfvhUFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/classes/[id].vue").then(m => m.default || m)
  },
  {
    name: completedeTS4ST2nFOMeta?.name ?? "classes-completed",
    path: completedeTS4ST2nFOMeta?.path ?? "/classes/completed",
    children: [],
    meta: completedeTS4ST2nFOMeta,
    alias: completedeTS4ST2nFOMeta?.alias || [],
    redirect: completedeTS4ST2nFOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/classes/completed.vue").then(m => m.default || m)
  },
  {
    name: createYNeHYeATl7Meta?.name ?? "classes-create",
    path: createYNeHYeATl7Meta?.path ?? "/classes/create",
    children: [],
    meta: createYNeHYeATl7Meta,
    alias: createYNeHYeATl7Meta?.alias || [],
    redirect: createYNeHYeATl7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/classes/create.vue").then(m => m.default || m)
  },
  {
    name: index0GHCrg5TyqMeta?.name ?? "classes",
    path: index0GHCrg5TyqMeta?.path ?? "/classes",
    children: [],
    meta: index0GHCrg5TyqMeta,
    alias: index0GHCrg5TyqMeta?.alias || [],
    redirect: index0GHCrg5TyqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/classes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93y4mDZ8ZBInMeta?.name ?? "classroom-id",
    path: _91id_93y4mDZ8ZBInMeta?.path ?? "/classroom/:id",
    children: [],
    meta: _91id_93y4mDZ8ZBInMeta,
    alias: _91id_93y4mDZ8ZBInMeta?.alias || [],
    redirect: _91id_93y4mDZ8ZBInMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/classroom/[id].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    children: [],
    meta: indexe9Brt5DfdhMeta,
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93945zNMKXhzMeta?.name ?? "lesson-report-id",
    path: _91id_93945zNMKXhzMeta?.path ?? "/lesson-report/:id",
    children: [],
    meta: _91id_93945zNMKXhzMeta,
    alias: _91id_93945zNMKXhzMeta?.alias || [],
    redirect: _91id_93945zNMKXhzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/lesson-report/[id].vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    children: [],
    meta: logint0AWlhQgM0Meta,
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93doBmNU7TLGMeta?.name ?? "notes-id",
    path: _91id_93doBmNU7TLGMeta?.path ?? "/notes/:id",
    children: [],
    meta: _91id_93doBmNU7TLGMeta,
    alias: _91id_93doBmNU7TLGMeta?.alias || [],
    redirect: _91id_93doBmNU7TLGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/notes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93xuG8tmUSKdMeta?.name ?? "recording-token",
    path: _91token_93xuG8tmUSKdMeta?.path ?? "/recording/:token",
    children: [],
    meta: _91token_93xuG8tmUSKdMeta,
    alias: _91token_93xuG8tmUSKdMeta?.alias || [],
    redirect: _91token_93xuG8tmUSKdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/recording/[token].vue").then(m => m.default || m)
  },
  {
    name: StudentSessionRowEnS3FqZ6uQMeta?.name ?? "student-components-StudentSessionRow",
    path: StudentSessionRowEnS3FqZ6uQMeta?.path ?? "/student/components/StudentSessionRow",
    children: [],
    meta: StudentSessionRowEnS3FqZ6uQMeta,
    alias: StudentSessionRowEnS3FqZ6uQMeta?.alias || [],
    redirect: StudentSessionRowEnS3FqZ6uQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/student/components/StudentSessionRow.vue").then(m => m.default || m)
  },
  {
    name: completed04Cu4gw5jIMeta?.name ?? "student-lessons-completed",
    path: completed04Cu4gw5jIMeta?.path ?? "/student/lessons/completed",
    children: [],
    meta: completed04Cu4gw5jIMeta,
    alias: completed04Cu4gw5jIMeta?.alias || [],
    redirect: completed04Cu4gw5jIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/student/lessons/completed.vue").then(m => m.default || m)
  },
  {
    name: indexyQLQbJARQqMeta?.name ?? "student-lessons",
    path: indexyQLQbJARQqMeta?.path ?? "/student/lessons",
    children: [],
    meta: indexyQLQbJARQqMeta,
    alias: indexyQLQbJARQqMeta?.alias || [],
    redirect: indexyQLQbJARQqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/student/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BlRZk9wzsdMeta?.name ?? "students-id",
    path: _91id_93BlRZk9wzsdMeta?.path ?? "/students/:id",
    children: [],
    meta: _91id_93BlRZk9wzsdMeta,
    alias: _91id_93BlRZk9wzsdMeta?.alias || [],
    redirect: _91id_93BlRZk9wzsdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/students/[id].vue").then(m => m.default || m)
  },
  {
    name: createH3MwhtYC9UMeta?.name ?? "students-create",
    path: createH3MwhtYC9UMeta?.path ?? "/students/create",
    children: [],
    meta: createH3MwhtYC9UMeta,
    alias: createH3MwhtYC9UMeta?.alias || [],
    redirect: createH3MwhtYC9UMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/students/create.vue").then(m => m.default || m)
  },
  {
    name: indexgabsFWvsCkMeta?.name ?? "students",
    path: indexgabsFWvsCkMeta?.path ?? "/students",
    children: [],
    meta: indexgabsFWvsCkMeta,
    alias: indexgabsFWvsCkMeta?.alias || [],
    redirect: indexgabsFWvsCkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/students/index.vue").then(m => m.default || m)
  }
]