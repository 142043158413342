import {addRouteMiddleware, defineNuxtPlugin, useCookie, useRuntimeConfig, useState} from "nuxt/app";
import { ofetch } from 'ofetch';

export default defineNuxtPlugin(() => {

    const auth = useState('auth', () => {
        return {
            user: null,
            loggedIn: false
        }
    });

    const config = useRuntimeConfig().nuxtSanctumAuth;

    const apiFetch = ofetch.create({
        baseURL: config.baseUrl,
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'X-XSRF-TOKEN-PORTAL': useCookie('XSRF-TOKEN-PORTAL').value
        }
    })

    addRouteMiddleware('auth:student', async () => {
        await getUser();

        if(auth.value.loggedIn === false){
            return config.redirects.login;
        }

        // If this is a staff member, we need to redirect them to a staff page...
        if(auth.value.user.student == 0){
            return config.redirects.home;
        }
    });

    addRouteMiddleware('auth:staff', async () => {
        await getUser();

        if(auth.value.loggedIn === false){
            return config.redirects.login;
        }

        // If this is a student trying to access a staff page, redirect them back.
        if(auth.value.user.student == 1){
            return config.redirects.studentHome;
        }
    });

    const getUser = async () => {
        if (auth.value.loggedIn && auth.value.user) {
            return auth.value.user
        }

        try {
            const user = await apiFetch(config.endpoints.user)
            if (user) {
                auth.value.loggedIn = true
                auth.value.user = user
                return user
            }
        } catch (error) {
            // console.log(error)
        }
    }

});
