import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/vercel/path0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_W6FGptIdHM from "/vercel/path0/node_modules/nuxt-sanctum-auth-custom-csrf/dist/runtime/plugin.mjs";
import GuardMiddlewares_TgdGziTUQ5 from "/vercel/path0/plugins/GuardMiddlewares.js";
import obtain_Kl9JyoaJ2k from "/vercel/path0/plugins/obtain.js";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_W6FGptIdHM,
  GuardMiddlewares_TgdGziTUQ5,
  obtain_Kl9JyoaJ2k
]